export default {
  apple_pay: [
    'paymentMethodNonce',
    'deviceData',
  ],
  pay_pal: [
    'payPalEmail',
    'paymentMethodNonce',
    'deviceData',
  ],
  paypal_complete: [
    'paymentMethodNonce',
    'deviceData',
  ],
  card: [
    'deviceData',
  ],
};
