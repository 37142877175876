import { loadScript } from "@paypal/paypal-js";
import loggerHelper from '../../loggerHelper';

let context = {};

const init = (ctx) => {
    context = ctx;
};

const hidePayPalButton = () => {
    document.querySelector(context.globalUserOptions.selectorForPayPalButton).style.display = 'none';
};

const receivedPayPalData = async (data) => {
    await context.createIframes();

    loadScript({ clientId: data.client_id, merchantId: data.merchant_id, dataUserIdToken: data.merchant_id_token })
        .then((paypal) => {
            paypal
                .Buttons({
                    createVaultSetupToken: () => { return data.setup_token; },
                    onApprove: (response) => {
                        hidePayPalButton();
                        payPalPaymentMethodNonceReceived(response.vaultSetupToken);
                    },
                    onError: (err) => {
                        context.globalCallbacks.onPayPalError(err);
                        loggerHelper.error('PayPal error:', err);
                    }
                })
                .render(context.globalUserOptions.selectorForPayPalButton)
                .catch((err) => {
                    context.globalCallbacks.onPayPalError(err);
                    loggerHelper.error('PayPal error:', err);
                });
        })
        .catch((err) => {
            context.globalCallbacks.onPayPalError(err);
            loggerHelper.error('PayPal error:', err);
        });
};

const payPalPaymentMethodNonceReceived = (nonce) => {
    context.sendMessageToMainIframe({ action: 'PAY_PAL_PAYMENT_METHOD_NONCE', data: { nonce } });
};

export default {
    init,
    receivedPayPalData,
};
