import { includeScripts } from '../includeHelper';
import initApplePay from './init';
import deviceDataHelper from '../device_data/init';

let context = {};

const init = (ctx) => {
  context = ctx;
};

const fetchApplePayData = () => {
  context.sendMessageToMainIframe({ action: 'FETCH_APPLE_PAY_DATA' });
}

const receivedApplePayData = async (data) => {
  await context.createIframes();
  const elemForApplePayButton = document.querySelector(context.globalUserOptions.selectorForApplePayButton);
  const applePayButton = document.createElement('button');
  applePayButton.id = 'cfy-apple-pay-button';
  applePayButton.style = '-webkit-appearance: -apple-pay-button; -apple-pay-button-type: plain; -apple-pay-button-style: black; height: 64px; width: 300px; display: none';
  applePayButton.lang = 'eng';

  elemForApplePayButton.appendChild(applePayButton);

  includeScripts(data.braintree_srcs).then(() => {
    const applePayModalOptions = {
      applePayLabel: context.globalUserOptions.applePayLabel,
      applePayAmount: context.globalUserOptions.applePayAmount,
    };
    const applePayCallbacks = {
      paymentMethodNonceReceived: applePayPaymentMethodNonceReceived,
      onApplePayAuthorized: context.globalCallbacks.onApplePayAuthorized,
      onApplePayNotSupported: context.globalCallbacks.onApplePayNotSupported,
      onApplePayError: context.globalCallbacks.onApplePayError,
    };

    initApplePay(context.globalUserOptions.selectorForApplePayButton, data.client_token, applePayModalOptions, applePayCallbacks);
    if (deviceDataHelper.isDeviceDataScriptIncluded(data.braintree_srcs)) {
      deviceDataHelper.initDeviceDataCollector(data.client_token);
    }
  });
};

const applePayPaymentMethodNonceReceived = (paymentMethodNonce) => {
  context.sendMessageToMainIframe({ action: 'APPLE_PAY_PAYMENT_METHOD_NONCE', data: paymentMethodNonce });
};

export default {
  init,
  fetchApplePayData,
  receivedApplePayData,
  applePayPaymentMethodNonceReceived,
};
