import config from './config/config';
import iframeHelper from './helpers/iframeHelper';
import optionsHelper from './helpers/optionsHelper';
import optionsValidationHelper from './helpers/optionsValidationHelper';
import callbacksValidationHelper from './helpers/callbacksValidationHelper';
import loggerHelper from './helpers/loggerHelper';
import arrayObjectHelper from './helpers/arrayObjectHelper';
import defaults from './config/fieldDefaults';
import threeDSecureHelper from './helpers/threeDSecureHelper';
import genericThreeDSecureHelper from './helpers/generic_three_d_secure/index';
import applePayHelper from './helpers/apple_pay/helper';
import deviceDataConfiguration from './helpers/device_data/configuration';
import deviceDataHelper from './helpers/device_data/init';
import payPalHelper from './helpers/pay_pal/index';
import braintreePayPalHelper from './helpers/pay_pal/braintree/index';
import payPalCompleteHelper from './helpers/pay_pal/paypal_complete/index';
import goCardlessHelper from './helpers/direct_debit/go_cardless';
import stripeDirectDebitHelper from './helpers/direct_debit/stripe_connect';
import directDebitHelper from './helpers/direct_debit';
import maxioPaymentsDirectDebitHelper from './helpers/direct_debit/maxio_payments';
import ChargifyBadger from './helpers/chargifyBadger.js';
import hosts from './validators/hosts';

function Chargify() {
  let loadUserOptions = {};
  let globalUserOptions = {};
  let globalCallbacks = {};
  let iframeElems = {};
  const tokenFunctions = {
    success: null,
    error: null,
    token: null,
  };

  let primaryIframeSelector = '';
  let jsHost;
  const threeDSFormData = { amount: 0 };
  let threeDSConfig = {};
  let threeDSSuccess = false;
  let validForm = false;
  let fieldsValues = {};
  const mainIframeId = '100';
  const store = {};
  let threeDsModalTriggered = false;
  let mainIframeName;
  const mainIframeStyles = { display: 'none' };

  const unload = function () {
    if (Object.keys(globalUserOptions).length !== 0) {
      window.removeEventListener('message', receiveMessage, false);

      Object.entries(iframeElems).forEach(([_index, iframe]) => iframe.remove());
    }

    maxioPaymentsDirectDebitHelper.unload();
    goCardlessHelper.unload();
  };

  const load = async function (userOptions, callbacks = {}) {
    try {
      unload();

      loadUserOptions = userOptions;
      userOptions.selector = userOptions.selector || globalUserOptions.selector;
      userOptions.publicKey = userOptions.publicKey || globalUserOptions.publicKey;
      userOptions.serverHost = userOptions.serverHost || globalUserOptions.serverHost;
      userOptions.deviceData = userOptions.deviceData || globalUserOptions.deviceData;
      globalCallbacks = callbacks;
      mainIframeName = iframeHelper.generateMainIframeName();

      // Set internal types for given payment types in order to skip first setup of globalUserOptions.fields
      // Once data is received in PAY_PAL_DATA_RECEIVED or DIRECT_DEBIT_DATA_RECEIVED hooks, we change
      // type to the once reflecting schema of form fields and reparseGlobalOptions to set previously skipped
      // globalUserOptions.fields
      // On this level we cannot yet establish which schema we want to use for gocardless nor if we are using
      // braintree paypal or paypal complete one.
      if (userOptions.type === 'gocardless') {
        console.warn("Using type 'gocardless' is now deprecated, you should use 'direct_debit' instead!");
        userOptions.type = 'direct_debit';
      }

      if (userOptions.type === 'pay_pal') {
        userOptions.type = 'pay_pal_init';
      }

      // Skip validation for those internal types
      const validateFields = !['direct_debit', 'pay_pal_init'].includes(userOptions.type);
      if (!validateOptionsAndCallbacks(validateFields)) return;

      jsHost = fetchJsHost();
      if (!jsHost) {
        console.error('Chargfify.js src URL is invalid'); // eslint-disable-line no-console
        return;
      }

      window.addEventListener('message', receiveMessage, false);

      // Skip default fields setup for internal types
      const skipDefaultFields = ['direct_debit', 'gocardless', 'pay_pal_init'].includes(userOptions.type);
      globalUserOptions = optionsHelper.getNormalizedOptions(loadUserOptions, skipDefaultFields);
      primaryIframeSelector = optionsHelper.getPrimaryIframeSelector(globalUserOptions);

      await createMainIframe();

      const context = getContext();
      applePayHelper.init(context);
      payPalHelper.init(context);
      braintreePayPalHelper.init(context);
      payPalCompleteHelper.init(context);
      deviceDataConfiguration.init(context);
      deviceDataHelper.init(context);
      stripeDirectDebitHelper.init(context);
      directDebitHelper.init(context);
      maxioPaymentsDirectDebitHelper.init(context);
      threeDSecureHelper.setContext(context);
      const goCardlessConfig = {
        globalUserOptions,
        globalCallbacks,
        iframeElems,
        primaryIframeSelector,
        loadUserOptions,
        iframeSrc: jsHost + config.server.iframePath,
        onIframeLoadCallback: sendMessage,
        createIframes,
        sendMessageToAllFields,
        sendMessageToMainIframe,
        token,
      };

      goCardlessHelper.init(goCardlessConfig);

      if (!userOptions.deviceData && globalUserOptions.fields != null) delete globalUserOptions.fields.deviceData;

      if (userOptions.type === 'direct_debit') {
        directDebitHelper.fetchDirectDebitData();
      } else if (userOptions.type === 'apple_pay') {
        applePayHelper.fetchApplePayData();
      } else if (userOptions.type === 'pay_pal_init') {
        payPalHelper.fetchPayPalData();
      } else if (userOptions.threeDSecure) {
        Object.keys(globalUserOptions.fields).forEach((fieldName) => {
          threeDSFormData[fieldName] = '';
        });

        const { serverHost, publicKey, gatewayHandle, deviceData, pspToken } = globalUserOptions;
        const message = {
          action: 'FETCH_THREE_D_SECURE_CONFIG',
          data: {
            serverHost: serverHost,
            publicKey: publicKey,
            gatewayHandle: gatewayHandle,
            deviceData: deviceData,
            pspToken: pspToken
          },
        };

        sendMessageToMainIframe(message);
      } else if (userOptions.deviceData && userOptions.type === 'card' && !userOptions.threeDSecure) {
        deviceDataConfiguration.fetchDeviceDataConfig();
      } else {
        await createIframes();
      }
    } catch (error) {
      ChargifyBadger.notify(error, window.location.href, globalUserOptions);
      throw (error);
    }
  };

  const verifyMicrodeposit = async function (userOptions, success, error, callbacks = {}) {
    if (!validateMicrodepositOptionsAndCallbacks(userOptions, success, error, callbacks)) return;

    try {
      userOptions.type = 'verify_microdeposit';
      loadUserOptions = userOptions;
      globalUserOptions = optionsHelper.getNormalizedOptions(loadUserOptions);
      globalCallbacks = callbacks;
      Object.assign(globalCallbacks, { onPlaidAccountVerificationSuccess: success, onPlaidAccountVerificationError: error});

      maxioPaymentsDirectDebitHelper.init(getContext());
      mainIframeName = iframeHelper.generateMainIframeName();
      await maxioPaymentsDirectDebitHelper.includePlaidScript();
      window.addEventListener('message', receiveMessage, false);
      await createMainIframe();
  
      sendMessageToMainIframe({ action: 'PLAID_FETCH_LINK_TOKEN_VERIFICATION', data: userOptions.token });
    } catch (error) {
      ChargifyBadger.notify(error, window.location.href, globalUserOptions);
      throw (error);
    }
  };

  // Depends on correctly initialised global variables
  const getContext = () => ({
    primaryIframeSelector,
    globalUserOptions,
    loadUserOptions,
    iframeElems,
    sendMessage,
    sendMessageToMainIframe,
    sendMessageToAllFields,
    createIframes,
    globalCallbacks,
    displayMainIframe,
    iframeSrc: jsHost + config.server.iframePath,
    token,
  });

  const changeMainIframeStylesToDefault = () => {
    iframeElems[mainIframeId].style = {}; // reset main-iframe style to default
    arrayObjectHelper.assign(iframeElems[mainIframeId].style, mainIframeStyles); // apply default main-iframe styles
  }

  const onThreeDSSuccess = (token, options = { skipCallingTokenFunction: false }) => {
    setThreeDsToken(token);
    if (!options.skipCallingTokenFunction) {
      tokenFunctions.token();
    }
  };

  const receivedThreeDSConfig = async function (data) {
    if (globalUserOptions.currency) data.currency = globalUserOptions.currency;
    threeDSConfig = {
      ...data,
      threeDSVerificationAmount: loadUserOptions.threeDSVerificationAmount,
    };

    primaryIframeSelector = optionsHelper.getPrimaryIframeSelector(globalUserOptions);
    await createIframes();

    threeDSecureHelper.init(threeDSConfig, {
      onSuccess: onThreeDSSuccess,
      onError: display3DSError,
      onThreeDsModal: onTreeDsModal,
    });
  };

  const receivedThreeDSConfigError = async function (data) {
    if (globalCallbacks.onThreeDsConfigError) {
      globalCallbacks.onThreeDsConfigError(data);
    }

    globalUserOptions.threeDSecure = false;

    await createIframes();

    if (globalUserOptions.deviceData) {
      deviceDataConfiguration.fetchDeviceDataConfig(false);
    }
  };

  var setThreeDsToken = function (token) {
    threeDSSuccess = true;
    sendMessageToMainIframe({ action: 'SET_THREE_DS_TOKEN', data: token });
  };

  var display3DSError = function (errorDescription, message = '3D Secure authentication failed.') {
    threeDSSuccess = false;
    if (tokenFunctions.error) {
      onTreeDsModal(false);
      tokenFunctions.error({ status: 400, errors: [message, errorDescription] });
    } else {
      return console.warn(message, errorDescription); // eslint-disable-line no-console
    }
  };

  const initCardAuthorization = function () {
    threeDSecureHelper.initCardAuthorization(threeDSFormData, threeDSConfig, {
      onSuccess: onThreeDSSuccess,
      onError: (error, message) => {
        const msg = message || '3D Secure authorization could not be started.';
        validForm = false;
        display3DSError(error, msg);
      },
      onThreeDsModal: onTreeDsModal,
      initGenericThreeDSecureModal: (formData, storeCardInitUrl) => {
        initGenericThreeDSecureModalIframe(formData, storeCardInitUrl);
      },
    });
  };

  const receiveValidationSuccess = function () {
    validForm = true;
    tokenFunctions.token();
  };

  const receiveValidationError = function () {
    validForm = false;
  };

  const fetchJsHost = function () {
    const scripts = document.scripts;

    for (let i = 0; i < scripts.length; i++) {
      const src = scripts[i].getAttribute('src');
      if (src == null) continue;

      const found = hosts.matchJsHost(src);
      if (found) return found[1].replace(/\/$/, '');
    }
  };

const displayMainIframe = () => {
  iframeElems[mainIframeId].style = {}; // reset main-iframe styles
  arrayObjectHelper.assign(iframeElems[mainIframeId].style, genericThreeDSecureHelper.iframeStyles());
}

  const initGenericThreeDSecureModalIframe = (formData, storeCardInitUrl = null) => {
    displayMainIframe();

    const message = {
      action: 'CREATE_GENERIC_THREE_D_SECURE_IFRAME',
      data: {
        formData: formData,
        storeCardInitUrl: storeCardInitUrl,
        styles: genericThreeDSecureHelper.iframeStyles(),
      },
    };

    sendMessageToMainIframe(message);
  };

  const mainIframeOptions = () => {
    return arrayObjectHelper.assign({}, globalUserOptions, {
      selfSelector: iframeHelper.mainIframeSelector,
      hostHref: window.location.href,
      mainIframeName: mainIframeName,
    });
  };

  const createMainIframe = async () => {
    const onLoadSendData = mainIframeOptions();
    const src = jsHost + config.server.mainIframePath;
    const options = {
      selector: iframeHelper.mainIframeSelector,
      name: mainIframeName,
      iframeId: mainIframeId,
      appendTo: document.body,
      iframeStyles: mainIframeStyles,
      src,
      onLoadSendData,
    };

    return createIframe(options);
  };

  const createIframe = async (options) => {
    const { selector, iframeId, appendTo, iframeStyles, styles, src, onLoadSendData } = options;
    const iframe = document.createElement('iframe');

    iframe.name = options.name
    iframe.allow = 'camera;microphone'
    arrayObjectHelper.assign(iframe.style, iframeStyles);

    if (styles) {
      Object.keys(globalUserOptions.style[selector]).forEach((eachStyle) => {
        iframe.style[eachStyle] = styles[eachStyle];
      });
    }

    appendTo.appendChild(iframe);
    iframe.contentWindow.document.open(`id${selector}`);
    iframe.contentWindow.location.replace(src);

    const iframeLoaded = new Promise((resolve) => {
      iframe.onload = () => {
        sendMessage({
          iframeId: iframeId,
          action: 'LOAD',
          data: onLoadSendData,
        });
        resolve(`loaded iframe${selector}`);
      };
    });

    iframe.contentWindow.document.close();
    iframeElems[iframeId] = iframe;

    return iframeLoaded;
  };

  const createIframes = async function () {
    const iframeSelectors = iframeHelper.getIframeSelectors(globalUserOptions);
    const loadedIframes = [];

    iframeSelectors.forEach((selector, iframeId) => {
      if (document.querySelector(selector) == null) {
        return;
      }

      const iframeStyles = { border: 'none', height: '84px' }; // initial value, overwritten dynamically
      const styles = globalUserOptions.style && globalUserOptions.style[selector];
      const onLoadSendData = arrayObjectHelper.assign({}, globalUserOptions, {
        selfSelector: selector,
        selfIndex: iframeId,
        primaryIframeSelector: primaryIframeSelector,
        mainIframeName: mainIframeName,
        hostHref: window.location.href,
      });
      const src = jsHost + config.server.iframePath;
      const options = {
        selector,
        iframeId,
        appendTo: document.querySelector(selector),
        name: iframeHelper.getIframeName(globalUserOptions.type, selector),
        iframeStyles,
        styles,
        src,
        onLoadSendData,
      }

      loadedIframes.push(createIframe(options));
    });

    return Promise.all(loadedIframes);
  };

  const resizeIframe = function (data) {
    try {
      const iframe = document.querySelector(`${data.selector} iframe`);

      if (!iframe) return;

      iframe.style.width = `${data.width}`;
      iframe.style.height = `${data.height}`;
    } catch (error) {
      ChargifyBadger.notify(error, window.location.href, globalUserOptions);
      throw (error);
    }
  };

  const token = externalFormData => (formEl, success, error) => {
    try {
      if (formEl == null) {
        return console.warn('first argument (form element) can not be blank'); // eslint-disable-line no-console
      }

      if (formEl != null && formEl.querySelectorAll == null) {
        return console.warn('first argument (form element) must be a valid form element'); // eslint-disable-line no-console
      }

      if (success == null) {
        return console.warn('second argument (successCallback) can not be blank'); // eslint-disable-line no-console
      }

      if (success != null && typeof success !== 'function') {
        return console.warn('second argument (successCallback) should be a function'); // eslint-disable-line no-console
      }

      if (error != null && typeof error !== 'function') {
        return console.warn('third argument (failureCallback) should be a function'); // eslint-disable-line no-console
      }

      tokenFunctions.success = success;
      tokenFunctions.error = error;
      tokenFunctions.token = function () {
        token(externalFormData)(formEl, success, error);
      };

      if (globalUserOptions.type === 'gocardless' && goCardlessHelper.goCardlessDataNotConfirmed()) {
        if (goCardlessHelper.noGoCardlessInformations()) return;

        goCardlessHelper.openGoCardlessConfirmationModal(formEl, success, error, externalFormData);

        return;
      } else if (isStripeDirectDebitAndDataNotConfirmed()) {
        stripeDirectDebitHelper.openDirectDebitConfirmationModal(formEl, success, error, externalFormData);

        return;
      } else if (loadUserOptions.threeDSecure && !threeDSSuccess) {
        const formData = readFormData(formEl, defaults, globalUserOptions, externalFormData, validForm);

        if (!validForm) {
          sendMessageToMainIframe({ action: 'VALIDATE_FORM', data: formData });
        } else {
          arrayObjectHelper.assign(threeDSFormData, formData);
          initCardAuthorization();
        }

        return;
      }

      onTreeDsModal(false);
      const formData = readFormData(formEl, defaults, globalUserOptions, externalFormData, false);
      sendMessageToMainIframe({ action: 'PROCESS_FORM', data: formData });
    } catch (error) {
      ChargifyBadger.notify(error, window.location.href, globalUserOptions);
      throw (error);
    }
  };

  const isStripeDirectDebitAndDataNotConfirmed = () => {
    return isStripeDirectDebit() && stripeDirectDebitHelper.directDebitDataNotConfirmed();
  }

  const isStripeDirectDebit = () => {
    return ['stripe_connect_sepa', 'stripe_connect_becs', 'stripe_connect_bacs'].includes(globalUserOptions.type)
  }

  var readFormData = function (formEl, defaults, globalUserOptions, externalFormData, readThreeDSVerificationAmount) {
    const formData = {};

    formEl.querySelectorAll('[data-chargify]').forEach((input) => {
      const fieldDefault = defaults[globalUserOptions.type][input.getAttribute('data-chargify')];
      if (fieldDefault && !fieldDefault.required) {
        formData[input.getAttribute('data-chargify')] = input.value;
      }
    });

    // When threeDSecure is enabled and form data is being read for 3DS
    // verification, we'll look for a verification amount provided by input.
    if (readThreeDSVerificationAmount) {
      const input = formEl.querySelector('[data-chargify="threeDSVerificationAmount"]');
      if (input) {
        formData[input.getAttribute('data-chargify')] = input.value;
      }
    }

    return { ...formData, ...externalFormData };
  };

  const sendMessageToMainIframe = (message) => {
    sendMessage({
      iframeId: mainIframeId,
      action: message.action,
      data: message.data,
    });
  };

  const sendMessageToAllFields = (message) => {
    Object.keys(iframeElems).forEach((iframeIndex) => {
      if (iframeHelper.isNameIncludesType(iframeElems[iframeIndex].name, globalUserOptions.type)) {
        sendMessage({
          iframeId: iframeIndex,
          action: message.action,
          data: message.data,
        });
      }
    });
  };

  var sendMessage = function (message) {
    try {
      loggerHelper.info('{chargifyjs} sendMessage', message);
      iframeElems[message.iframeId].contentWindow.postMessage(message, jsHost);
    } catch (error) {
      ChargifyBadger.notify(error, window.location.href, globalUserOptions);
      throw (error);
    }
  };

  const handleTokenError = (data) => {
    const { gateway } = threeDSConfig;
    const { threeDSData, requiresMicrodeposit } = data;

    if (threeDSecureHelper.challengeRequested(gateway, threeDSData)) {
      // adyen run this code twice, first time with threeDSData.type as "threeDS2Fingerprint" and second time with "threeDS2Challenge"
      if (gateway !== 'adyen' || threeDSData.type === 'threeDS2Challenge') onTreeDsModal(true);
      threeDSecureHelper.initChallenge(gateway, threeDSData, onThreeDSSuccess);
    } else {
      try {
        tokenFunctions.error(data);
        if (requiresMicrodeposit) { globalCallbacks.onPlaidAccountVerificationPending(data.token); }
      } catch (error) {
        // ignore error in callback so it won't be send to hb
      }
    }
  };

  const onTreeDsModal = (active) => {
    if (active && threeDsModalTriggered) return; // prevents calling callback twice
    if (!active && !threeDsModalTriggered) return;

    threeDsModalTriggered = active;
    globalCallbacks.onTreeDsModal(threeDsModalTriggered);
  };

  const validateOptionsAndCallbacksResult = (optionsValidation, callbacksValidation) => {
    if (!optionsValidation.result || !callbacksValidation.result) {
      const errors = [...optionsValidation.errors, ...callbacksValidation.errors];
      console.warn('Chargify options are invalid', errors); // eslint-disable-line no-console
      return false;
    }
    return true;
  };

  const validateOptionsAndCallbacks = (validateFields) => {
    const optionsValidation = optionsValidationHelper.validateOptions(loadUserOptions, validateFields);
    const callbacksValidation = callbacksValidationHelper.validateCallbacks(globalCallbacks);
    return validateOptionsAndCallbacksResult(optionsValidation, callbacksValidation);
  };
  
  const validateMicrodepositOptionsAndCallbacks = (options, success, error, callbacks) => {
    if (success == null) {
      console.warn('second argument (successCallback) can not be blank'); // eslint-disable-line no-console
      return false;
    }
    if (success != null && typeof success !== 'function') {
      console.warn('second argument (successCallback) should be a function'); // eslint-disable-line no-console
      return false;
    }
    if (error != null && typeof error !== 'function') {
      console.warn('third argument (failureCallback) should be a function'); // eslint-disable-line no-console
      return false;
    }
    jsHost = fetchJsHost();
    if (!jsHost) {
      console.error('Chargfify.js src URL is invalid'); // eslint-disable-line no-console
      return false;
    }

    const optionsValidation = optionsValidationHelper.validateMicrodepositOptions(options);
    const callbacksValidation = callbacksValidationHelper.validateCallbacks(callbacks);
    return validateOptionsAndCallbacksResult(optionsValidation, callbacksValidation);
  };

  const reparseGlobalUserOptions = () => {
    globalUserOptions = optionsHelper.getNormalizedOptions(loadUserOptions);
    primaryIframeSelector = optionsHelper.getPrimaryIframeSelector(globalUserOptions);
    // when globalUserOptions changes we have to update them in the main iframe
    sendMessageToMainIframe({ action: 'LOAD', data: mainIframeOptions() });
  }

  const receiveMessage = async (event) => {
    try {
      const jsHostFragments = jsHost.split('/');
      const allowedOrigin = `${jsHostFragments[0]}//${jsHostFragments[2]}`;

      if (event.origin !== allowedOrigin) {
        loggerHelper.error('{chargifyjs} - message received from another host: ', `${event.origin} ${allowedOrigin}`);
        return;
      }

      const message = event.data;
      loggerHelper.info('{chargifyjs} receiveMessage', message);

      switch (message.action) {
        case 'GET_TOKEN_SUCCESS':
          try {
            tokenFunctions.success(message.data.token, message.data.message);
          } catch (error) {
            // ignore error in callback so it won't be send to hb
          }
          goCardlessHelper.setGoCardlessDataConfirmed(false);
          stripeDirectDebitHelper.setDirectDebitDataConfirmed(false);
          break;

        case 'GET_TOKEN_ERROR':
          handleTokenError(message.data);
          goCardlessHelper.setGoCardlessDataConfirmed(false);
          stripeDirectDebitHelper.setDirectDebitDataConfirmed(false);
          break;

        case 'RESIZE_IFRAME':
          resizeIframe(message.data);
          break;

        case 'GOCARDLESS_COUNTRY_CHANGE':
          goCardlessHelper.handleGoCardlessSettingsForCountryCode(message.data);
          break;

        case 'GOCARDLESS_INIT_TOGGLE_IBAN_OR_LOCAL':
          goCardlessHelper.handleGoCardlessIntiToggleIbanOrLocal();
          break;

        case 'GOCARDLESS_DATA_RECEIVED':
          // we need to change this in order to GoCardless works when the type was set as "direct_debit"
          loadUserOptions.type = 'gocardless';
          loadUserOptions.scheme = message.data.direct_debit_scheme;

          reparseGlobalUserOptions();

          if (validateOptionsAndCallbacks()) {
            goCardlessHelper.receivedGoCardlessData(message.data);
            globalCallbacks.onReceivedGoCardlessConfiguration();
          }
          break;

        case 'GOCARDLESS_TOGGLE_IBAN_OR_LOCAL_DETAILS':
          goCardlessHelper.toggleIbanOrLocalDetailsFields();
          break;

        case 'APPLE_PAY_DATA_RECEIVED':
          applePayHelper.receivedApplePayData(message.data);
          break;

        case 'APPLE_PAY_DATA_ERROR':
          globalCallbacks.onApplePayError(message.data);
          break;

        case 'APPLE_PAY_PAYMENT_METHOD_NONCE_RECEIVED':
          globalCallbacks.onApplePayAuthorized();
          break;

        case 'PAY_PAL_DATA_RECEIVED':
          if (message.data.gateway === 'paypal_complete') {
            loadUserOptions.type = 'paypal_complete';
            reparseGlobalUserOptions();

            if(validateOptionsAndCallbacks()) {
              payPalCompleteHelper.receivedPayPalData(message.data);
            }
          } else {
            loadUserOptions.type = 'pay_pal';
            reparseGlobalUserOptions();
            braintreePayPalHelper.receivedPayPalData(message.data);
          }

          break;

        case 'PAY_PAL_DATA_ERROR':
          globalCallbacks.onPayPalError(message.data);
          break;

        case 'PAY_PAL_PAYMENT_METHOD_NONCE_RECEIVED':
          globalCallbacks.onPayPalAuthorized();
          break;

        case 'THREE_D_SECURE_FORM_DATA':
          Object.keys(message.data).forEach((fieldName) => {
            threeDSFormData[fieldName] = message.data[fieldName];
          });
          threeDSSuccess = false;
          validForm = false;
          break;

        case 'THREE_D_SECURE_CONFIG_RECEIVED':
          receivedThreeDSConfig(message.data);
          break;

        case 'PLAID_LINK_TOKEN_RECEIVED':
          maxioPaymentsDirectDebitHelper.initPlaidLink(message.data.token, message.data.oneTimeToken);
          break;

        case 'PLAID_LINK_TOKEN_ERROR':
          globalCallbacks.onPlaidLinkTokenError(message.data);
          break;

        case 'PLAID_ACCOUNT_VERIFICATION_ERROR':
          globalCallbacks.onPlaidAccountVerificationError(message.data);
          break;

        case 'PLAID_ACCOUNT_VERIFICATION_SUCCESS':
          globalCallbacks.onPlaidAccountVerificationSuccess(message.data);
          break;

        case 'HIDE_IFRAME':
          goCardlessHelper.hideIframe(message.data);
          break;

        case 'SHOW_IFRAME':
          goCardlessHelper.showIframe(message.data);
          break;

        case 'VALIDATION_SUCCESS':
          receiveValidationSuccess();
          break;

        case 'VALIDATION_ERROR':
          receiveValidationError();
          break;

        case 'FETCH_THREE_D_SECURE_CONFIG_ERROR':
          receivedThreeDSConfigError(message.data);
          break;

        case 'GENERIC_THREE_D_SECURE_CARD_TOKEN':
          changeMainIframeStylesToDefault();
          setThreeDsToken(message.data);
          tokenFunctions.token();
          break;

        case 'REMOVE_GENERIC_THREE_D_SECURE_MODAL_IFRAME':
          changeMainIframeStylesToDefault();
          break;

        case 'CARD_TYPE_DETECTED':
          if (store.cardType !== message.data.type) {
            store.cardType = message.data.type;
            globalCallbacks.onCardTypeDetected(message.data.type);
          }
          break;
        case 'CALL_ON_THREE_DS_SUCCESS':
          onThreeDSSuccess(message.data.json, { skipCallingTokenFunction: message.data.skipCallingTokenFunction });
          if (message.data.skipCallingTokenFunction) changeMainIframeStylesToDefault();
          break;
        case 'DEVICE_DATA_CONFIG_RECEIVED':
          deviceDataConfiguration.receivedDeviceDataConfig(message.data);
          break;

        case 'FETCH_DEVICE_DATA_CONFIG_ERROR':
          deviceDataConfiguration.receivedDeviceDataConfigError(message.data);
          break;

        case 'DIRECT_DEBIT_DATA_RECEIVED':
          // we need to change the type from 'direct_debit' to 'stripe_connect_sepa' here in order to properly
          // read default fields configuration
          if ((message.data.gateway === 'maxio_payments' || message.data.gateway === 'maxp') &&
              message.data.direct_debit_scheme === 'ach_plaid' &&
              loadUserOptions.plaidMode === 'skip') {
            loadUserOptions.type = 'maxio_payments_ach';
          } else {
            loadUserOptions.type = `${message.data.gateway}_${message.data.direct_debit_scheme}`;
          }

          reparseGlobalUserOptions();

          if (validateOptionsAndCallbacks()) {
            if (isStripeDirectDebit()) {
              stripeDirectDebitHelper.receivedDirectDebitData(message.data);
            } else if (globalUserOptions.type === 'maxio_payments_ach_plaid' || globalUserOptions.type === 'maxp_ach_plaid') {
              await maxioPaymentsDirectDebitHelper.includePlaidScript();
              await maxioPaymentsDirectDebitHelper.createIframes();
              sendMessageToMainIframe({ action: 'PLAID_FETCH_LINK_TOKEN' });
            } else {
              await maxioPaymentsDirectDebitHelper.createIframes();
            }

            globalCallbacks.onReceivedDirectDebitConfiguration();
          }
          break;

        case 'DIRECT_DEBIT_DATA_ERROR':
          globalCallbacks.onDirectDebitReceiveConfigurationError();
          // this is just for backward compatiblity
          globalCallbacks.onGoCardlessReceiveConfigurationError();
          break;

        case 'DIRECT_DEBIT_FORM_DATA_FOR_MODAL':
          stripeDirectDebitHelper.setDirectDebitFormDataForModal(message.data);
          break;

        case 'GENERIC_THREE_D_SECURE_MODAL_OPENED':
          onTreeDsModal(true);
          break;

        case 'GENERIC_THREE_D_SECURE_MODAL_CLOSED':
          onTreeDsModal(false);
          break;

        case 'SET_FIELDS_VALUES':
          fieldsValues = message.data;
          stripeDirectDebitHelper.setDirectDebitFormDataForModal(fieldsValues);
          goCardlessHelper.setGoCardlessFormDataForModal(fieldsValues);
          break;

        case 'ADDRESS_CHANGE':
          globalCallbacks.onAddressChange(message.data);
          break;

        default:
          loggerHelper.error('{chargifyjs} invalid action: ', message.action);
      }
    } catch (error) {
      ChargifyBadger.notify(error, window.location.href, globalUserOptions);
      throw (error);
    }
  };

  const internal = f => (...args) => {
    if (hosts.validateInternalAllowedClientHost()) return f(...args);

    throw 'Access forbidden. Chargify._accessInternal is not part of the public interface.';
  };

  const isPaymentProfileProvided = () => Object.values(fieldsValues).some(value => value !== '');

  this.load = load;
  this.token = token({});
  this.verifyMicrodeposit = verifyMicrodeposit;
  this.unload = unload;
  this.isPaymentProfileProvided = isPaymentProfileProvided;
  this._internal = { token: internal(token) };
  this._accessInternal = internal(() => ({
    token,
    goCardless: goCardlessHelper.internalFunctions,
    getDeviceDataToken: deviceDataHelper.getDeviceDataToken,
  }));
}

window.Chargify = Chargify;
