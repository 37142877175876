/* globals braintree paypal */

import { includeScripts } from '../../includeHelper';
import loggerHelper from '../../loggerHelper';
import deviceDataHelper from '../../device_data/init';
import initBraintreeClient from '../../braintree_blue/init';

let context = {};

const init = (ctx) => {
  context = ctx;
};

const initPayPal = (braintreeToken) => {
  initBraintreeClient(braintreeToken).then(clientInstance => braintree.paypalCheckout.create({ client: clientInstance })).then(paypalCheckoutInstance => paypalCheckoutInstance.loadPayPalSDK({ vault: true })).then(paypalCheckoutInstance => paypal.Buttons({ // eslint-disable-line new-cap
    fundingSource: paypal.FUNDING.PAYPAL,
    createBillingAgreement: () => paypalCheckoutInstance.createPayment({
      flow: 'vault',
      enableShippingAddress: true,
    }),
    onApprove: data => paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
      hidePayPalButton();
      payPalPaymentMethodNonceAndEmailReceived(payload.nonce, payload.details.email);
    }),
    onError: (err) => {
      context.globalCallbacks.onPayPalError(err);
      loggerHelper.error('PayPal error:', err);
    },
  }).render(context.globalUserOptions.selectorForPayPalButton))
    .catch((err) => {
      context.globalCallbacks.onPayPalError(err);
      loggerHelper.error('PayPal error:', err);
    });
};

const hidePayPalButton = () => {
  document.querySelector(context.globalUserOptions.selectorForPayPalButton).style.display = 'none';
};

const receivedPayPalData = async (data) => {
  await context.createIframes();

  includeScripts(data.braintree_srcs)
      .then(() => {
          initPayPal(data.client_token);
      })
      .then(() => {
          if (deviceDataHelper.isDeviceDataScriptIncluded(data.braintree_srcs)) {
              deviceDataHelper.initDeviceDataCollector(data.client_token);
          }
      });
};

const payPalPaymentMethodNonceAndEmailReceived = (nonce, email) => {
  context.sendMessageToMainIframe({ action: 'PAY_PAL_PAYMENT_METHOD_NONCE_AND_EMAIL', data: { nonce, email } });
};

export default {
  init,
  receivedPayPalData,
};
