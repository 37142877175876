import tingle from 'tingle.js';
import stylesForDirectDebitModal from '../../stylesForDirectDebitModal';

const directDebitFormDataForModal = {};
let directDebitDataConfirmed = false;
let stripeDirectDebitInformation = {};
let context = {};

const init = (ctx) => {
  context = ctx;
};

const initDirectDebit = () => {
  stylesForDirectDebitModal.injectToPage();
};

const receivedDirectDebitData = async (data) => {
  stripeDirectDebitInformation = data;
  await context.createIframes();
  initDirectDebit();
};

const setDirectDebitDataConfirmed = (value) => { directDebitDataConfirmed = value; };

const setDirectDebitFormDataForModal = (data) => {
  Object.keys(data).forEach((fieldName) => {
    directDebitFormDataForModal[fieldName] = data[fieldName];
  });
};

const openDirectDebitConfirmationModal = (formEl, success, error, externalFormData) => {
  if (Object.keys(externalFormData).length !== 0) {
    setDirectDebitFormDataForModal(externalFormData);
  }

  const modal = new tingle.modal({ // eslint-disable-line new-cap
    footer: true,
    stickyFooter: false,
    closeMethods: ['overlay', 'button', 'escape'],
    closeLabel: 'Close',
    cssClass: ['custom-class-1', 'custom-class-2'],
    onClose: () => {
      if (!directDebitDataConfirmed) {
        context.globalCallbacks.onCancelDirectDebitModal();
      }
    },
    onOpen: () => {
      const holder = `${directDebitFormDataForModal.firstName || ''} ${directDebitFormDataForModal.lastName || ''}`;
      document.querySelector('#gc_holder').innerHTML = holder;

      if (context.globalUserOptions.type == 'stripe_connect_sepa') {
        const iban = directDebitFormDataForModal.bankIban || '';
        document.querySelector('#gc_iban').innerHTML = iban;
      }

      if (context.globalUserOptions.type == 'stripe_connect_becs') {
        const branchCode = directDebitFormDataForModal.branchCode || '';
        document.querySelector('#gc_branch_code').innerHTML = branchCode;

        const accountNumber = directDebitFormDataForModal.accountNumber || '';
        document.querySelector('#gc_bank_account_number').innerHTML = accountNumber;
      }

      if (context.globalUserOptions.type == 'stripe_connect_bacs') {
        const branchCode = directDebitFormDataForModal.branchCode || '';
        document.querySelector('#gc_branch_code').innerHTML = branchCode;

        const accountNumber = directDebitFormDataForModal.accountNumber || '';
        document.querySelector('#gc_bank_account_number').innerHTML = accountNumber;
      }

      const bankName = directDebitFormDataForModal.bankName || '';

      if (bankName && document.querySelector('#gc_bank_name')) {
        document.querySelector('#gc_bank_name').innerHTML = bankName;
      }

      const email = directDebitFormDataForModal.email || '';

      if (email && document.querySelector('#gc_email')) {
        document.querySelector('#gc_email').innerHTML = email;
      }
    },
  });

  let content = '<h2>Direct Debit Mandate</h2>';
  content += '<table class="gc_info">';

  if (context.globalUserOptions.type == 'stripe_connect_sepa') {
    content += '<tr id="gc_iban_wrapper">';
    content += '<td id="gc_iban_label" class="label">IBAN</td>';
    content += '<td id="gc_iban"></td>';
    content += '</tr>';
  } else if (context.globalUserOptions.type == 'stripe_connect_becs') {
    content += '<tr id="gc_branch_code_wrapper">';
    content += '<td id="gc_branch_code_label" class="label">Branch code</td>';
    content += '<td id="gc_branch_code"></td>';
    content += '</tr>';

    content += '<tr id="gc_bank_account_number_wrapper">';
    content += '<td id="gc_bank_account_number_label" class="label">Account Number</td>';
    content += '<td id="gc_bank_account_number"></td>';
    content += '</tr>';
  } else if (context.globalUserOptions.type == 'stripe_connect_bacs') {
    content += '<tr id="gc_branch_code_wrapper">';
    content += '<td id="gc_branch_code_label" class="label">Sort Code</td>';
    content += '<td id="gc_branch_code"></td>';
    content += '</tr>';

    content += '<tr id="gc_bank_account_number_wrapper">';
    content += '<td id="gc_bank_account_number_label" class="label">Account Number</td>';
    content += '<td id="gc_bank_account_number"></td>';
    content += '</tr>';
  }

  content += '<tr>';
  content += '<td id="gc_holder_label" class="label">Holder</td>';
  content += '<td id="gc_holder"></td>';
  content += '</tr>';

  content += '<tr id="gc_email_wrapper">';
  content += '<td id="gc_email_label" class="label">Email</td>';
  content += '<td id="gc_email"></td>';
  content += '</tr>';
  content += '</table>';

  if (context.globalUserOptions.type == 'stripe_connect_sepa') {
    content += '<p class="agreement">';
    content += 'By providing your payment information and confirming, you ';
    content += `authorise ${stripeDirectDebitInformation.seller_name} and Stripe, our payment service provider, to `;
    content += 'send instructions to your bank to debit your account and your bank to ';
    content += 'debit your account in accordance with those instructions.';
    content += '</p>';

    content += '<p class="agreement">';
    content += 'As part of your rights, you are entitled to a refund from your bank under the terms and ';
    content += 'conditions of your agreement with your bank. A refund must be claimed within 8 weeks ';
    content += 'starting from the date on which your account was debited. Your rights are explained in ';
    content += 'a statement that you can obtain from your bank. You agree to receive notifications ';
    content += 'for future debits up to 2 days before they occur.';
    content += '</p>';
  } else if (context.globalUserOptions.type == 'stripe_connect_becs') {
    content += '<p class="agreement">';
    content += 'By providing your bank account details, you agree to this Direct Debit Request and the Direct Debit ';
    content += 'Request service agreement, and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct ';
    content += 'Debit User ID number 507156 (&quot;Stripe&quot;) to debit your account through the Bulk Electronic Clearing ';
    content += `System (BECS) on behalf of ${stripeDirectDebitInformation.seller_name} (the &quot;Merchant&quot;) for any `;
    content += 'amounts separately communicated to you by the Merchant. You certify that you are either an account ';
    content += 'holder or an authorised signatory on the account listed above.';
    content += '</p>';
  } else if (context.globalUserOptions.type == 'stripe_connect_bacs') {
    content += '<p class="agreement">';
    content += 'I understand that Stripe will be collecting Direct Debits ';
    content += `on behalf of ${stripeDirectDebitInformation.seller_name} (the &quot;Merchant&quot;) and confirm that I am the account holder `;
    content += 'and the only person required to authorise debits from this account.';
    content += '</p>';
  }

  modal.setContent(content);

  modal.addFooterBtn('Confirm', 'tingle-btn tingle-btn--primary tingle-btn--pull-right', () => {
    directDebitDataConfirmed = true;
    modal.close();
    context.token(externalFormData)(formEl, success, error);
  });

  modal.addFooterBtn('Cancel', 'tingle-btn tingle-btn--default', () => {
    modal.close();
  });

  modal.open();
};

const directDebitDataNotConfirmed = () => !directDebitDataConfirmed;

export default {
  init,
  receivedDirectDebitData,
  directDebitDataNotConfirmed,
  openDirectDebitConfirmationModal,
  setDirectDebitDataConfirmed,
  setDirectDebitFormDataForModal,
};
