import arrayHelper from './arrayObjectHelper';

const mainIframeSelector = '#chargify-js-main-iframe';

const getIframeSelectors = ({ fields }) => {
  const selectors = [];

  Object.keys(fields).forEach((fieldName) => {
    const selector = fields[fieldName].selector;
    if (!arrayHelper.includes(selectors, selector)) {
      selectors.push(selector);
    }
  });

  return selectors;
};

const generateMainIframeName = () =>
  `${mainIframeSelector}%${[...Array(30)].map(() => Math.random().toString(36)[2] || '0').join('')}`;

const getIframeName = (type, selector) => `%${type}%${selector}`;

const isNameIncludesType = (name, type) => name && name.includes(`%${type}%`);

const rendersField = (fieldName, fields, iframeSelector) => {
  if (!fields[fieldName]) return false;

  return iframeSelector === fields[fieldName].selector;
};

export default {
  getIframeSelectors,
  getIframeName,
  rendersField,
  isNameIncludesType,
  mainIframeSelector,
  generateMainIframeName,
};
