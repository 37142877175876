/* global bluesnap */

const showErrors = (errors, callbacks) => {
  let errorMessage = '';
  errors.forEach((error) => { errorMessage = `${errorMessage} ${error} `; });
  console.warn('3DS Error:', errorMessage);
  callbacks.onError('The customer cannot be authenticated. Please try again or use another payment method.');
};

const initThreeDsPaymentsSetup = (payment_fields_token, callbacks) => {
  bluesnap.on('threeDsChallengeExecuted', () => {
    //console.log('challenge modal!!!');
    callbacks.onThreeDsModal(true)
  });

  bluesnap.threeDsPaymentsSetup(payment_fields_token, (sdkResponse) => { //eslint-disable-line no-undef
    const code = sdkResponse.code;

    if (code === '1') {
      const threeDSecure = sdkResponse.threeDSecure;
      callbacks.onSuccess(threeDSecure.threeDSecureReferenceId);
    } else {
      showErrors(sdkResponse.info.errors || [], callbacks)

      if (sdkResponse.info.warnings) {
        console.warn('3DS Warning:', sdkResponse.info.warnings);
      }
    }
  });
}

const convertToExpDate = (month, year) => {
  const expYear = year.slice(-2);
  const expMonth = month.padStart(2, '0');
  return `${expMonth}/${expYear}`;
}

const verifyCard = (formData, config) => {
  bluesnap.threeDsPaymentsSubmitData({ //eslint-disable-line no-undef
    ccNumber: formData.number,
    cvv: formData.cvv,
    expDate: convertToExpDate(formData.month, formData.year),
    amount: 0,
    currency: config.currency,
  });
}

const init = async (config, callbacks) => {
  config.payment_fields_token
  initThreeDsPaymentsSetup(config.payment_fields_token, callbacks)
}

export default {
  init,
  verifyCard
};
