let context = {};

const init = (ctx) => {
    context = ctx;
};

const fetchPayPalData = () => {
    context.sendMessageToMainIframe({ action: 'FETCH_PAY_PAL_DATA' });
};

export default {
    init,
    fetchPayPalData,
};
