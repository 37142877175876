import tingle from 'tingle.js';
import stylesForDirectDebitModal from '../stylesForDirectDebitModal';

let onError;

const init = (callbacks) => {
  onError = callbacks.onError
  stylesForDirectDebitModal.injectToPage();
}

const startAuthorization = (callbacks) => {
  callbacks.onSuccess();
}

const showChallengeModal = (threeDSData, onThreeDSSuccess) => {
  if (document.querySelector('#checkout-three-ds-container')) return;

  const iframe = document.createElement('iframe');
  iframe.id = 'checkout-three-ds-container';
  iframe.src = threeDSData.href
  iframe.style.border = 'none';
  iframe.style.background = '#FFF';
  iframe.style.opacity = '1';
  iframe.style.minHeight = '550px';
  iframe.style.minWidth = '450px';
  iframe.style.overflow = 'hidden';
  iframe.style.position = 'relative';
  iframe.style.display = 'block';
  iframe.style.margin = '0 auto';

  const handleMessage = (message) => {
    const { type, card_id, customer_id, three_ds_token } = message.data;

    if (type === 'CHECKOUT_CARD_AND_CUSTOMER_ID') {
      onThreeDSSuccess({ type, card_id, customer_id, three_ds_token });
      modal.close();
    }

    if (type === 'CLOSE_AFTER_FAIL') {
      onError('The customer cannot be authenticated. Please try again or use another payment method.');
      modal.close();
    }
  }

  const modal = new tingle.modal({ // eslint-disable-line new-cap
    footer: true,
    stickyFooter: false,
    closeMethods: ['overlay', 'button', 'escape'],
    closeLabel: 'Close',
    onOpen: () => {
      window.addEventListener('message', handleMessage);
    },
    onClose: () => {
      window.removeEventListener('message', handleMessage);
      document.querySelectorAll('.tingle-modal').forEach(elem => elem.remove());
    },
  });

  modal.setContent(iframe);
  modal.open();
}

export default {
  init,
  showChallengeModal,
  startAuthorization,
};
