const browserPrefixes = {
  placeholder: [
    '::-webkit-input-placeholder',
    '::-moz-placeholder',
    ':-ms-input-placeholder',
    ':-moz-placeholder',
    '::placeholder',
  ],
};

const hasBrowserPrefixes = (pseudoStyleName) => {
  const browserPrefixesKey = pseudoStyleName.replace(/:+/, '');

  return browserPrefixes.hasOwnProperty(browserPrefixesKey);
}

const cssObjectToCssTextWithBrowserPrefixes = (element, pseudoStyleName, styles) => {
  return browserPrefixes[pseudoStyleName.replace(/:+/,'')].map((prefix) => {
    return `#${element.id}${prefix} { ${cssObjectToCssText(styles)} }`;
  }).join(' ');
};

const cssObjectToCssText = (cssObject) => {
  return Object.keys(cssObject).map((style) => {
    return `${style.split(/(?=[A-Z])/).join('-').toLowerCase()}: ${cssObject[style]};`;
  }).join(' ');
};

// We allow to use also 'placeholder' to maintain backward compatibility
// see https://github.com/chargify/chargify-js/pull/13
const isPseudoClassOrElem = (style) => style.startsWith(':') || style === 'placeholder';

const cssStyleTextForElement = (element, pseudoClassName, styles) => {
  if (hasBrowserPrefixes(pseudoClassName)) {
    return cssObjectToCssTextWithBrowserPrefixes(element, pseudoClassName, styles);
  } else {
    return `#${element.id}${pseudoClassName} { ${cssObjectToCssText(styles)} }`;
  }
};

const addCssStyleTextToPage = (cssStyleText) => {
  const existingStyleNode = document.querySelector('style');
  const styleNode = existingStyleNode || document.createElement('style');

  const styleText = document.createTextNode(cssStyleText);
  styleNode.appendChild(styleText);

  if (!existingStyleNode) document.getElementsByTagName('head')[0].appendChild(styleNode);
};

export default {
  isPseudoClassOrElem,
  cssStyleTextForElement,
  addCssStyleTextToPage,
}
