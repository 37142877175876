const availableCallbacks = [
  'onThreeDsConfigError',
  'onApplePayAuthorized',
  'onApplePayNotSupported',
  'onApplePayError',
  'onPayPalAuthorized',
  'onPayPalError',
  'onReceivedGoCardlessConfiguration',
  'onCancelGoCardlessModal',
  'onGoCardlessReceiveConfigurationError',
  'onCardTypeDetected',
  'onDirectDebitReceiveConfigurationError',
  'onCancelDirectDebitModal',
  'onReceivedDirectDebitConfiguration',
  'onTreeDsModal',
  'onAddressChange',
  'onPlaidLinkTokenError',
  'onPlaidComplete',
  'onPlaidExit',
  'onPlaidAccountVerificationPending',
  'onPlaidAccountVerificationError',
  'onPlaidAccountVerificationSuccess'
];

const validateCallbacks = (callbacks) => {
  const errors = [];

  if (callbacks && typeof callbacks !== 'object') {
    errors.push('second argument of load must be an object');
  }

  availableCallbacks.forEach((callbackName) => {
    if (callbacks[callbackName] && typeof callbacks[callbackName] !== 'function') {
      errors.push(`'${callbackName}' must be a function`);
    } else if (!callbacks[callbackName]) {
      callbacks[callbackName] = () => {};
    }
  });

  return { result: errors.length === 0, errors: errors };
};

export default {
  validateCallbacks,
};
